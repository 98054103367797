import React, { forwardRef } from 'react';
import logo from '../../img/logo.png';

const Footer = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="dark:bg-neutral-950 dark:bg-opacity-50 dark:text-white"
    >
      <div className="w-full max-w-screen-xl mx-auto md:py-8">
        <div
          className="sm:flex sm:items-center sm:justify-between"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <img src={logo} alt="logo" className="dark:invert text-white h-10" />
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href="/#" className="mr-4 hover:underline md:mr-6 ">
                Home
              </a>
            </li>
            <li>
              <a href="/#" className="mr-4 hover:underline md:mr-6 ">
                Faq
              </a>
            </li>
            <li>
              <a href="/#" className="hover:underline">
                Contact
              </a>
            </li>
            <li>
              <a href="www.vezzai.com" className="hover:underline">
                VezzAi
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023
          <a href="https://vezzco.com/" className="hover:underline">
            VezzCo™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
});
export default Footer;
