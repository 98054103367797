import React from 'react';

export default function FlipCard(props) {
  return (
    <>
      <div className="flip-card ">
        <div className="flip-card-inner ">
          <div
            className={`flip-card-front rounded-lg shadow-md flex flex-col justify-center p-[10%]  bg-neutral-900 bg-opacity-80`}
          >
            <p className="text-xl">{props.title}</p>
            <p>{props.problem}</p>
          </div>
          <div
            className={`flip-card-back text rounded-lg shadow-md flex flex-col justify-center  p-[10%] bg-neutral-400 text-black`}
          >
            <p> {props.solution}</p>
          </div>
        </div>
      </div>
    </>
  );
}
