import React from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';

const ContactInfoCard = () => {
  return (
    <div className="flex items-center flex-col lg:flex-row  py-10 text-white rounded-lg p-8 shadow-lg mx-auto">
      <div className="text-left lg:px-5">
        <h2 className="text-3xl font-bold mb-6">Contact Me</h2>
        <p className="text-gray-300 mb-8">
          Feel free to reach out to me for collaboration, inquiries, or just to
          say hello. I'll get back to you as soon as possible.
        </p>
      </div>
      <div className="lg:w-[60%] lg:px-5 flex flex-col space-y-4 text-gray-400">
        <div className="flex items-center ">
          <FaEnvelope className="text-xl mr-4" />
          <a className="hover:text-white" href="mailto:iwa.jakub92@gmail.com">
            iwa.jakub92@gmail.com
          </a>
        </div>
        <div className="flex items-center">
          <FaPhone className="text-xl mr-4" />
          <a className="hover:text-white" href="tel:+4407506789685">
            +44 07506789685
          </a>
        </div>
        <div className="flex items-center">
          <FaMapMarkerAlt className="text-xl mr-4" />
          <p>Leicester, UK</p>
        </div>
        <div className="flex items-center ">
          <FaGlobe className="text-xl mr-4" />
          <a className="hover:text-white" href={'www.vezzai.com'}>
            VezzAi
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoCard;
