import React, { useRef } from 'react';
import Main from './components/pages/Main';
import Faq from './components/pages/Faq';
import Footer from './components/elements/Footer';
import Head from './components/elements/Head';
import Nav from './components/elements/Nav';
import ScrollToTop from './components/elements/ScrollToTop';
import Contact from './components/pages/Contact';

function App() {
  const mainRef = useRef(null);
  const faqRef = useRef(null);
  const footerRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <div className="flex flex-col">
      <Nav
        mainRef={mainRef}
        faqRef={faqRef}
        footerRef={footerRef}
        contactRef={contactRef}
      />
      <Head />
      <Main ref={mainRef} />
      <Faq ref={faqRef} />
      <Contact ref={contactRef} />
      <Footer ref={footerRef} />
      <ScrollToTop />
    </div>
  );
}

export default App;
