import React from 'react';
import FlipCard from './Cards/FlipCard';

const cardData = [
  {
    title: "What are websites for business?",
    problem: "Problem: In a digital era, a business without an online presence is invisible.",
    solution: "Solution: Over 70% of people have access to the internet. Online presence expands business reach worldwide."
  },
  {
    title: "Why are websites important for your business?",
    problem: "Problem: Limited reach and visibility hinder business growth.",
    solution: "Solution: A well-crafted website enhances visibility and boosts business prospects."
  },
  {
    title: "What service do I provide?",
    problem: "Problem: Difficulty in finding a reliable and skilled development team.",
    solution: "Solution: I offer comprehensive web development services tailored to business goals."
  },
  {
    title: "What is SEO?",
    problem: "Problem: Low online visibility, affecting customer acquisition.",
    solution: "Solution: SEO improves visibility on search engines and attracts more potential customers."
  },
  {
    title: "Why is SEO important?",
    problem: "Problem: A business without an online presence is invisible.",
    solution: "Solution: SEO boosts website ranking, driving organic traffic and enhancing online presence."
  },
  {
    title: "What are mine SEO strategies?",
    problem: "Problem: Lack of effective strategies leads to ineffective SEO.",
    solution: "Solution: I provide in-depth keyword research, content creation, and technical enhancements."
  },
  {
    title: "What is website security services?",
    problem: "Problem: Cyber threats pose a huge risk to every business.",
    solution: "Solution: I provide modern security services, including encryption, vulnerability assessments, and proactive monitoring."
  },
  {
    title: "Why is website security important?",
    problem: "Problem: Vulnerable websites are prone to data breaches.",
    solution: "Solution: I ensure your business remains secure, safeguarding reputation and customer trust."
  },
  {
    title: "How I approach security?",
    problem: "Problem: Many businesses struggle to implement effective security measures.",
    solution: "Solution: I take a proactive approach with advanced security protocols and monitoring."
  }
];

export default function MainGrid() {
  return (
    <div className="gap-2 mx-auto lg:grid lg:grid-cols-3 lg:grid-rows-3 lg:w-[75vw] pb-10">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="h-[35vmax] lg:max-h-80 flex justify-center"
          data-aos={`fade-${index % 2 === 0 ? 'down-right' : 'down'}`}
          data-aos-duration="1500"
        >
          <FlipCard title={card.title} problem={card.problem} solution={card.solution} />
        </div>
      ))}
    </div>
  );
}